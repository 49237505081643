import React, { useEffect, useState, useRef } from 'react';
import ExperienceDetails from '../components/ExperienceDetails/ExperienceDetail';
import { useLocation, useParams } from 'react-router-dom';
import { getCurrencySymbol } from "../utils/converCurrencySymbol";
import { formatEventTime, formattedTime, formatEventDate } from "../utils/dateConversion";
import { trackExperienceDetailView } from "../utils/googleAnalyticsReport";
import axios from 'axios';
import { CircularProgress, Box } from '@mui/material';
import { fbTrackExperienceDetailView } from "../utils/facebookPixelTracking";
import { getStateFromZip } from '../utils/commonMethods';

const ExperienceDetail: React.FC = () => {
    const location = useLocation();
    const eventData = location.state?.event;
    const [experience, setExperience] = useState(eventData);
    const [loading, setLoading] = useState(true); // Set loading to true only if eventData is not available
    const { id } = useParams<{ id: string }>();
    const hasTracked = useRef(false);
    const formatLocation = (location: string, cityName: string): string => {
        location = location.replace(/\b(St|Rd)\b(?!\.)/g, '$1.');
        location = location.replace(/(?<!\b(St|Rd))\./g, ',');
        location = location.replace(/,\s*$/, '');  // Remove trailing comma if any
        location = location.replace(new RegExp(`,\\s*${cityName}\\s*,`), `, ${cityName},`);
        // Remove any remaining commas
        location = location.replace(/,\s*/g, ' ').trim();

        return location;
    };
    const mapEventData = (actualData: any): any => {
        return actualData.map((event: any) => {
            const date = new Date(event.schedule.startTimestamp);
            const formattedDate = date.toLocaleDateString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
            const totalPrice = `${getCurrencySymbol(event.price.priceCurrency)} ${event.price.total}/person`;
            const pricePerPerson = `${event.price.pricePerClassPerPerson}`;
            let location = event.classLocation.locationString || '';
            location = location.replace(/,\s*\d{5}(?:-\d{4})?/, '');
            location = location.replace(/,\s*[A-Z]{2,3}$/, '');
            location = formatLocation(location, event.classLocation.cityName);
            const state = getStateFromZip(event?.classLocation?.zipCode);
            if (state) {
                location += `, ${state}`;
            }
            return {
                title: event.classTitle,
                // date: formattedDate,
                date: formatEventDate(event.schedule.startTimestamp, event?.classLocation?.zipCode),
                time: formattedTime(event.schedule.startTimestamp, event.schedule.endTimestamp, event?.classLocation?.zipCode),
                formatTime: formatEventTime(event.schedule.startTimestamp, event.schedule.endTimestamp, event?.classLocation?.zipCode),
                description: event.classDesc,
                price: totalPrice,
                // location: event.classLocation.makeLocationPublic === "true" ? event.classLocation.locationString : `${event.classLocation.cityName} , ${event.classLocation.countryId}`,
                location: event.classLocation.makeLocationPublic === "true"
                    ? location
                    : `${event.classLocation.cityName}${state ? `, ${state}` : ''}`,
                imageUrl: event.coverImageUrl,
                classId: event.classId,
                cityName: event?.classLocation?.cityName,
                maxExplorers: event?.maxExplorers,
                pricePerPerson: pricePerPerson,
                serviceCharge: event.price.serviceCharge,
                guideId: event.guideId,
                scheduledTimeStamps: event.scheduledTimeStamps,
                availableSeatCount: event.availableSeatCount,
                cutOffTime: event.cutOffTime,
            };
        });
    };

    useEffect(() => {
        const fetchClassData = async () => {
            if (id) {
                try {
                    setLoading(true); // Start loading
                    const API_URL = `${process.env.REACT_APP_BASE_API_URL}/v1/class/getClass/${id}`;
                    const response = await axios.get(API_URL);
                    const mappedEvents = mapEventData([response.data]);
                    setExperience(mappedEvents[0]);
                } catch (error) {
                    console.error("Error fetching class data:", error);
                } finally {
                    setLoading(false); // Stop loading
                }
            }
        };

        if (!eventData) {
            fetchClassData();
        } else {
            setLoading(false);
        }
        if (!hasTracked.current) {
            trackExperienceDetailView(location.pathname);
            if (process.env.REACT_APP_ENVIRONMENT === 'production') {
                fbTrackExperienceDetailView(id, location.pathname);
            }
            hasTracked.current = true;
        }
    }, [id, eventData, location.pathname]);

    return loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
        </Box>
    ) : experience ? (
        <ExperienceDetails experience={experience} />
    ) : (
        <p>No event data available</p>
    );
};

export default ExperienceDetail;
