import ReactGA from "react-ga4";
let measurementId = process.env.REACT_APP_MEASUREMENT_ID || "";
ReactGA.initialize(measurementId); // Replace with your GA4 Measurement ID

// Track Home Page View
export const trackHomePageView = (pagePath:string) => {
  ReactGA.event("web_experience_list_view", { page_path: pagePath });
};

// Track Experience Detail View
export const trackExperienceDetailView = (pagePath:string) => {
  ReactGA.event("web_experience_detail_view", { page_path: pagePath });
};

// Track "Book Experience" button clicks
export const trackBookExperienceClick = (experienceId:string, experienceName:string) => {
  ReactGA.event("web_book_experience_click", {
    experience_id: experienceId,
    experience_name: experienceName,
  });
};

// Track Completed Transactions
export const trackCompletedTransaction = (experienceId:string, experienceName:string, value:any) => {
  ReactGA.event("web_transaction_completed", {
    experience_id: experienceId,
    experience_name: experienceName,
    value: parseFloat(value), // Convert to number
  });
};
