import ReactPixel from 'react-facebook-pixel';

// Get the Facebook Pixel ID from environment variables
const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID || "";

// Initialize Facebook Pixel
const initFacebookPixel = () => {
  if (pixelId) {
    ReactPixel.init(pixelId, undefined, {
      autoConfig: true,
      debug: process.env.NODE_ENV !== 'production'
    });
    console.log("Facebook Pixel initialized successfully");
  } else {
    console.warn("Facebook Pixel ID is not configured");
  }
};

// Track Home Page View
export const trackHomePageView = () => {
  ReactPixel.track('ViewContent', {
    content_name: 'Home Page',
    content_category: 'Page View'
  });
};

// Track Experience List View
export const fbTrackExperienceListView = (pagePath?: string) => {
  ReactPixel.track('ViewContent', {
    content_name: 'Experience List',
    content_category: pagePath || 'Experience List'
  });
};

// Track Experience Detail View
export const fbTrackExperienceDetailView = (
  experienceId: any,
  pagePath: string, 
) => {
  ReactPixel.track('ViewContent', {
    content_ids: [experienceId],
    content_name: 'Experience Details',
    content_category: pagePath || 'Experience Details'
  });
};

// Track "Book Experience" button clicks
export const fbTrackBookExperienceClick = (
  experienceId: string, 
  experienceName: string, 
) => {
  ReactPixel.track('InitiateCheckout', {
    content_ids: [experienceId],
    content_name: experienceName,
  });
};

// Track Completed Transactions
export const fbTrackCompletedTransaction = (
  experienceId: string, 
  experienceName: string, 
  transactionValue: any
) => {
  ReactPixel.track('Purchase', {
    content_ids: [experienceId],
    content_name: experienceName,
    value: transactionValue,
    currency: 'USD'
  });
};

// Export the initialization function
export { initFacebookPixel };