import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Container, CircularProgress } from '@mui/material';
import ExperienceList from '../components/ExperienceList/ExperienceList';
import axios from 'axios';
import { getCurrencySymbol } from "../utils/converCurrencySymbol";
import { formatEventTime, formattedTime, formatEventDate } from "../utils/dateConversion";
import { getStateFromZip } from '../utils/commonMethods'
import { trackHomePageView } from '../utils/googleAnalyticsReport';
import { useLocation } from "react-router-dom";
import SearchBar from '../components/SearchBar';
import { fbTrackExperienceListView } from '../utils/facebookPixelTracking';

interface Event {
    imageUrl: string;
    title: string;
    date: string;
    time: string;
    location: string;
    description: string;
    price: string;
    classId: string;
    cityName: string;
    maxExplorers: number;
    pricePerPerson: string;
    serviceCharge: string;
    guideId: string;
    scheduledTimeStamps: any;
    zipCode: string;
    hosted_by: any;
    availableSeatCount: number;
    cutOffTime: any;
}

interface ActualEvent {
    classTitle: string;
    classDesc: string;
    coverImageUrl: string;
    schedule: {
        startTimestamp: string;
        endTimestamp: string;
    };
    price: {
        priceCurrency: string;
        total: number;
        serviceCharge: string;
        pricePerClassPerPerson: string;
    };
    classLocation: {
        locationString: string;
        addressLine1: string;
        cityName: string;
        zipCode: string;
        countryId: string;
        makeLocationPublic: string;
    };
    classId: string;
    maxExplorers: number;
    guideId: string;
    scheduledTimeStamps: [
        {
            scheduleStatus: string,
            isGuideCheckedIn: boolean,
            scheduleId: string
        }
    ],
    guideFirstName: string;
    guideLastName: string;
    availableSeatCount: number;
    cutOffTime: any;
}

const API_URL = `${process.env.REACT_APP_BASE_API_URL}/v1/class/dashboardClassList?page=1&limit=50`;
const formatLocation = (location: string, cityName: string): string => {
    location = location.replace(/\b(St|Rd)\b(?!\.)/g, '$1.');
    location = location.replace(/(?<!\b(St|Rd))\./g, ',');
    location = location.replace(/,\s*$/, '');  // Remove trailing comma if any
    location = location.replace(new RegExp(`,\\s*${cityName}\\s*,`), `, ${cityName},`);
    // Remove any remaining commas
    location = location.replace(/,\s*/g, ' ').trim();

    return location;
};

const mapEventData = (actualData: ActualEvent[]): Event[] => {
    return actualData.map(event => {
        const date = new Date(event.schedule.startTimestamp);
        const formattedDate = date.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }).replace(',', '');

        const totalPrice = `${getCurrencySymbol(event.price.priceCurrency)}${event.price.total.toFixed(2)}/person`;
        const pricePerPerson = `${event.price.pricePerClassPerPerson}`;
        let location = event.classLocation.locationString || '';
        location = location.replace(/,\s*\d{5}(?:-\d{4})?/, '');
        location = location.replace(/,\s*[A-Z]{2,3}$/, '');
        location = formatLocation(location, event.classLocation.cityName);
        const state = getStateFromZip(event?.classLocation?.zipCode);
        if (state) {
            location += `, ${state}`;
        }
        return {
            title: event.classTitle,
            // date: formattedDate,
            date: formatEventDate(event.schedule.startTimestamp, event?.classLocation?.zipCode),
            time: formattedTime(event.schedule.startTimestamp, event.schedule.endTimestamp, event?.classLocation?.zipCode),
            formatTime: formatEventTime(event.schedule.startTimestamp, event.schedule.endTimestamp, event?.classLocation?.zipCode),
            description: event.classDesc,
            price: totalPrice,
            location: event.classLocation.makeLocationPublic === "true"
                ? location
                : `${event.classLocation.cityName}${state ? `, ${state}` : ''}`,
            imageUrl: event.coverImageUrl,
            classId: event.classId,
            cityName: event?.classLocation?.cityName,
            maxExplorers: event?.maxExplorers,
            pricePerPerson: pricePerPerson,
            serviceCharge: event.price.serviceCharge,
            guideId: event.guideId,
            scheduledTimeStamps: event.scheduledTimeStamps,
            zipCode: event?.classLocation?.zipCode,
            hosted_by: `${event.guideFirstName}`,
            // hosted_by:`${event.guideFirstName} ${event.guideLastName}`,
            availableSeatCount: event.availableSeatCount,
            cutOffTime: event.cutOffTime



        };
    });
};

const Experiences: React.FC = () => {
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const location = useLocation();
    const hasTracked = useRef(false); // To track if pageview has been sent
    // const [selectedCategory, setSelectedCategory] = useState<string | null>(null); // State for the selected icon category
    const fetchEvents = async (
        category?: any,
        city?: any,
        start_date?: any,
        end_date?: any,
        date?: any // New date parameter
    ) => {
        try {
            setLoading(true);
            console.log("category =>", category);
            // Build the query parameters
            const categoryQueryParam = category && category !== 'All' ? `&category=${encodeURIComponent(category)}` : '';
            console.log("categoryQueryParam =>", categoryQueryParam);

            const cityQueryParam = city ? `&cityname=${encodeURIComponent(city)}` : '';
            const dateQueryParam = date ? `&date=${encodeURIComponent(date)}` : ''; // Date query parameter
            const dateRangeParams = start_date && end_date ? `&start_date=${encodeURIComponent(start_date)}&end_date=${encodeURIComponent(end_date)}` : '';

            // Construct the final URL
            const response = await axios.get(`${API_URL}?${categoryQueryParam}${cityQueryParam}${dateQueryParam}${dateRangeParams}`);

            const eventData: ActualEvent[] = response?.data?.data;
            const mappedEvents = mapEventData(eventData);
            setEvents(mappedEvents);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Error fetching events. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEvents();


        // Track pageview only if it hasn't been tracked yet
        if (!hasTracked.current && location.pathname === '/experiences') {
            trackHomePageView('/experiences');
            if (process.env.REACT_APP_ENVIRONMENT === 'production') {
                fbTrackExperienceListView('/experiences');
            }
            hasTracked.current = true;
        }

    }, [location.pathname]);

    const handleSearchClick = (city: string, date: any, start_date: any, end_date: any, category: any) => {
        console.log("city =>", city);
        console.log("date =>", date);
        console.log("category =>", category);

        if (category) {
            if (category === "Beverages") {
                category = 'Wine & Beverages'
            }
            if (category === "Technology") {
                category = 'Design & Tech'
            }
        }

        // Normalize city if it contains a comma
        if (city && city.includes(",")) {
            city = city.split(",")[0];
        }

        // Conditions for fetching events
        if (!city && !date && !start_date && !end_date && !category) {
            return fetchEvents(null, null, null, null, null);
        }

        if (start_date && end_date && category) {
            return fetchEvents(category, city, start_date, end_date, null);
        }

        if (category && city && date) {
            return fetchEvents(category, city, null, null, date); // Expecting date to be included
        }

        if (city && category) {
            return fetchEvents(category, city, null, null, null);
        }

        if (city && date) {
            return fetchEvents(null, city, null, null, date);
        }

        if (city && start_date && end_date && category) {
            return fetchEvents(category, city, start_date, end_date, null);
        }

        if (start_date && end_date) {
            return fetchEvents(null, null, start_date, end_date, null);
        }

        if (category && date) {
            return fetchEvents(category, null, null, null, date);
        }

        if (date) {
            return fetchEvents(null, null, null, null, date);
        }

        if (city) {
            return fetchEvents(null, city, null, null, null);
        }

        if (category) {
            return fetchEvents(category, null, null, null, null);
        }
    };
    const content = useMemo(() => {
        if (loading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            );
        }

        if (error) {
            return <p>{error}</p>;
        }

        return events.length > 0 ? <ExperienceList events={events} /> :
            <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ margin: 0 }}>No experiences available.</p>
            </div>
    }, [loading, error, events]);

    return (
        <Container style={{ fontFamily: 'Poppins, Arial, Helvetica, Verdana, Tahoma, sans-serif', maxWidth: 'none', width: '100%' }}>
            <SearchBar onSearchClick={handleSearchClick} />
            {content}
        </Container>
    );
};

export default Experiences;
