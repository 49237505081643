import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface EventListProps {
    events: {
        imageUrl: string;
        title: string;
        date: string;
        time: string;
        location: string;
        description: string;
        price: string;
        classId: string;
        pricePerPerson: string;
        serviceCharge: string;
        guideId: string;
        scheduledTimeStamps: any;
        hosted_by:string;
        availableSeatCount: number; 
        cutOffTime:any;
    }[];
}

const EventList: React.FC<EventListProps> = ({ events }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    // const handleViewEvent = (eventData: any) => {
    //     const classId = decodeURIComponent(eventData.classId);
    //     navigate(`/experiences/${classId}`, { state: { event: eventData } });
    // };
    const handleViewEvent = (event: React.MouseEvent, eventData: any) => {
        const classId = decodeURIComponent(eventData.classId);
        
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab if Ctrl (Windows/Linux) or Command (Mac) is pressed
            window.open(`/experiences/${classId}`, '_blank');
        } else {
            // Navigate within the same screen
            navigate(`/experiences/${classId}`, { state: { event: eventData } });
        }
    };
    function truncateDescription(text: string, limit: number = 150): string { if (text.length > limit) { return text.slice(0, limit) + '...'; } return text; }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, marginTop:isMobile? 7:20, marginBottom:isMobile? 6:10 }}>
            {events?.map((event, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            sm: 'row'
                        },
                        alignItems: {
                            xs: 'stretch',
                            sm: 'flex-start'
                        },
                        borderRadius: '8px',
                        overflow: 'hidden',
                        // boxShadow: 2,
                        backgroundColor: 'background.paper',
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            width: { xs: '100%', sm: 400 },
                            height: { xs: 200, sm: 250 },
                            marginTop: 4,
                            cursor: 'pointer', // Set cursor to pointer
                            '&:hover': {
                                cursor: 'pointer', // Change cursor on hover
                            },
                        }}
                        onClick={(e) => handleViewEvent(e, event)}
                        >
                        {/* Image */}
                        <Box
                            component="img"
                            sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            }}
                            src={event.imageUrl}
                            alt={event.title}
                        />

                        {/* Logic for showing "Booking Closed" */}
                        {(() => {
                            const now = moment();
                            const startTime = moment(event.scheduledTimeStamps[0].startTimeStamp);
                            const endTime = moment(event.scheduledTimeStamps[0].endTimeStamp);

                            // Show "Booking Closed" if availableSeatCount is 0
                            if (event.availableSeatCount === 0) {
                            return (
                                <Box
                                sx={{
                                    position: 'absolute',
                                    top: 10,
                                    left: 10,
                                    width: '30%',
                                    // height: '100%',
                                    // display: 'flex',
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                    textAlign:'center',
                                    padding:'5px 10px',
                                    borderRadius:'10px',
                                    backgroundColor: 'rgba(255, 0, 0, 0.8)', // Red background with 50% opacity
                                }}
                                >
                                <Typography
                                    variant="h6"
                                    sx={{
                                    color: '#fff', // White text color
                                    // fontWeight: 'bold',
                                    }}
                                >
                                    Sold Out
                                </Typography>
                                </Box>
                            );
                            }

                            // Check cutOffTime logic
                            if (event.cutOffTime && event.cutOffTime !== 'none') {
                            const cutOffHours = +event.cutOffTime.split(' ')[0];
                            const hoursDifference = startTime.diff(now, 'hours');
                            if (hoursDifference < cutOffHours) {
                                return (
                                <Box
                                    sx={{
                                    position: 'absolute',
                                    top: 10,
                                    left: 10,
                                    width: isMobile ?  '50%' : '40%',
                                    // height: '100%',
                                    // display: 'flex',
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                    textAlign:'center',
                                    padding:'5px 10px',
                                    borderRadius:'10px',
                                    backgroundColor: 'rgba(255, 0, 0, 0.8)', // Red background with 50% opacity
                                    }}
                                >
                                    <Typography
                                    variant="h6"
                                    sx={{
                                        color: '#fff', // White text color
                                        // fontWeight: 'bold',
                                    }}
                                    >
                                    Booking Closed
                                    </Typography>
                                </Box>
                                );
                            }
                            }

                            // Check if cutOffTime is 'none' and if the event has ended
                            if (event.cutOffTime === 'none' && now.isAfter(endTime)) {
                            return (
                                <Box
                                sx={{
                                    position: 'absolute',
                                    top: 10,
                                    left: 10,
                                    width: isMobile ?  '50%' : '40%',
                                    // height: '100%',
                                    // display: 'flex',
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                    textAlign:'center',
                                    padding:'5px 10px',
                                    borderRadius:'10px',
                                    backgroundColor: 'rgba(255, 0, 0, 0.8)', // Red background with 50% opacity
                                }}
                                >
                                <Typography
                                    variant="h6"
                                    sx={{
                                    color: '#fff', // White text color
                                    // fontWeight: 'bold',
                                    }}
                                >
                                    Booking Closed
                                </Typography>
                                </Box>
                            );
                            }

                            return null; // No "Booking Closed" if none of the conditions are met
                        })()}
                        </Box>

                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            padding: {
                                xs: 2,
                                sm: 3
                            }
                        }}
                    >
                        <Box>
                        <Box onClick={(e) => handleViewEvent(e, event)} sx={{ cursor: 'pointer' }}>
                            <Typography 
                                variant="h6" 
                                component="div" 
                                sx={{ 
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                            >
                                {event.title}
                            </Typography>
                        </Box>
                            <Typography variant="body2" sx={{ marginTop: 1 }}>
                                {event.date} {event.time}
                            </Typography>
                            <Typography variant="body2" >
                                {event.location}
                            </Typography>
                            <Typography variant="body2">
                                 Guide: {event.hosted_by}
                            </Typography>
                            <Typography variant="body1" sx={{ marginTop: 2 }}>
                                {truncateDescription(event.description)}
                            </Typography>
                        </Box>
                        <Box sx={{ marginTop: 2 }}>
                            <Typography variant="body2">
                                {event.price}
                            </Typography>
                            
                           
                            <Button
                                variant="contained"
                                sx={{
                                    marginTop: 2,
                                    backgroundColor: '#2D3178',
                                    color: 'white',
                                    borderRadius: '30px',
                                    padding: '8px 12px',
                                    textTransform: 'none',
                                    fontSize: '0.95rem',
                                    '&:hover': {
                                        backgroundColor: 'darkgrey',
                                    }
                                }}
                                // onClick={() => handleViewEvent(event)} 
                                onClick={(e) => handleViewEvent(e, event)}
                            >
                                View Experience
                            </Button>
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default EventList;
